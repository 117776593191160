import React from "react";

import Layout, { LayoutData } from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import { PageSection } from "../components/page-section";

function NotFoundPage(props: Props) {
  return (
    <Layout {...props}>
      <SEO title="404: Nenalezeno" />
      <PageSection>
        <h2>
          404 - stránka nenalezena.
        </h2>
        <p>Je nám líto, stránku, kterou hledáte, nemáme.</p>
      </PageSection>
    </Layout>
  );
}

interface Props {
  data: LayoutData
}

export const pageQuery = graphql`
  query NotFoundPage {
    ...LayoutFragment
  }`

export default NotFoundPage;
